import React, { useState, useEffect } from 'react';
import axios from 'axios';

const Expenses = ({ token }) => {
  const [expenses, setExpenses] = useState([]);
  const [amount, setAmount] = useState('');
  const [category, setCategory] = useState('');
  const [date, setDate] = useState('');
  const [description, setDescription] = useState('');
  const [message, setMessage] = useState('');

  useEffect(() => {
    const fetchExpenses = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/expense`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setExpenses(response.data);
      } catch (err) {
        setMessage(err.response.data.message);
      }
    };

    fetchExpenses();
  }, [token]);

  const handleAddExpense = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/expense`,
        { amount, category, date, description },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setExpenses([...expenses, response.data.expense]);
      setAmount('');
      setCategory('');
      setDate('');
      setDescription('');
    } catch (err) {
      setMessage(err.response.data.message);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('token'); // Remove the token from localStorage
    window.location.reload(); // Reload the page to reset the state
  };

  return (
    <div className="p-4">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-bold">Expenses</h2>
        <button onClick={handleLogout} className="bg-red-500 text-white p-2 rounded">Logout</button>
      </div>
      {message && <p className="mb-4 text-red-500">{message}</p>}
      <form className="mb-4" onSubmit={handleAddExpense}>
        <input
          type="number"
          placeholder="Amount"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          className="w-full p-2 mb-2 border border-gray-300 rounded"
        />
        <input
          type="text"
          placeholder="Category"
          value={category}
          onChange={(e) => setCategory(e.target.value)}
          className="w-full p-2 mb-2 border border-gray-300 rounded"
        />
        <input
          type="date"
          value={date}
          onChange={(e) => setDate(e.target.value)}
          className="w-full p-2 mb-2 border border-gray-300 rounded"
        />
        <input
          type="text"
          placeholder="Description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          className="w-full p-2 mb-2 border border-gray-300 rounded"
        />
        <button type="submit" className="w-full bg-blue-500 text-white p-2 rounded">Add Expense</button>
      </form>
      <ul>
        {expenses.map((expense) => (
          <li key={expense._id} className="border p-2 mb-2">
            {expense.amount} - {expense.category} - {new Date(expense.date).toLocaleDateString()} - {expense.description}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Expenses;
