import React, { useState, useEffect } from 'react';
import Register from './components/Register';
import Login from './components/Login';
import Expenses from './components/Expense';

const App = () => {
  const [token, setToken] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setToken(token);
    }
  }, []);

  if (!token) {
    return (
      <div>
        <Login setToken={setToken} />
        <Register />
      </div>
    );
  }

  return <Expenses token={token} />;
};

export default App;
